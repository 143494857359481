
<template>
  <div class="page-container">
    <div class="global-image">
      <img src="../../public/circle-2.png" />
    </div>
    <h1>{{ title }}</h1>
    <h4>{{ subtitle }}</h4>

    <div v-if="loading" style="display: flex; color: #FFF; align-items: center; justify-content: center; height: 100vh; margin-top: -160px;">
      <div class="loader book">
        <figure class="page"></figure>
        <figure class="page"></figure>
        <figure class="page"></figure>
      </div>
    </div>

    <div class="post-columns" v-else>
      <div class="post-container">
        <div v-for="(post, index) in allPosts" :key="index" class="post-card">
          <div class="post-header">
            <h3>
              <a :href="post.link || post.url" target="_blank" class="post-title">
                {{ post.title }}
              </a>
            </h3>
            <span class="post-meta">
              <span class="badge" :class="post.source">@{{ post.source }}</span>
              {{ date }} {{ formatDate(post.pubDate || post.created_at) }}
            </span>
          </div>
          <!-- <div class="post-content">
            <p>{{ post.contentSnippet || post.description }}</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { translations } from "@/utils/recomendations";

export default {
  data() {
    return {
      mediumPosts: [],
      devtoPosts: [],
      loading: true,
    };
  },
  mounted() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  async created() {
    try {
      const mediumResponse = await axios.get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@hosanabarcelos"
      );
      this.mediumPosts = mediumResponse.data.items.map((post) => ({
        ...post,
        source: "medium",
      }));

      const devtoResponse = await axios.get(
        "https://dev.to/api/articles?username=hosana"
      );
      this.devtoPosts = devtoResponse.data.map((post) => ({
        ...post,
        source: "dev.to",
      }));
    } catch (error) {
      console.error("Erro ao carregar posts:", error);
    }
  },
  computed: {
    ...mapState(["language"]),
    allPosts() {
      return [...this.mediumPosts, ...this.devtoPosts].sort((a, b) => {
        const dateA = new Date(a.pubDate || a.created_at);
        const dateB = new Date(b.pubDate || b.created_at);
        return dateB - dateA;
      });
    },
    title() {
      return this.language ? "Veja minhas publicações!" : "Check out my posts!";
    },
    subtitle() {
      return this.language
        ? "Leia o que eu tenho escrevido e compartilhado."
        : "Read what I have written and shared.";
    },
    date() {
      return this.language ? "Publicado em" : "Published in";
    },
  },
  methods: {
    formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    if (this.language) {
      return `${day}/${month}/${year}`;
    } else {
      return `${month}/${day}/${year}`;
    }
    }
  },
};
</script>

<style lang="scss" scoped>

.global-image {
  position: absolute;
  bottom: -20px;
  right: 0;
  z-index: -2;
  opacity: 0.3;

  img {
    width: 280px;
    height: 390px;
  }
}

a {
    &:hover {
      border-bottom: 1px solid rgb(154, 98, 218) !important;
    }
}

h1 {
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: block;
    font-size: 2rem;
    margin-top: 200px;
}

h4 {
  color: #efedfdb3;
  margin: 12px auto 0;
  max-width: 455px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;
}

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  flex-direction: column;
}

.post-columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.post-container {
  /* max-height: 400px;
  overflow-y: auto;
  width: 100%;
  max-width: 800px;
  padding: 0 20px; */

  overflow-y: scroll;
    width: 70%;
    height: 80vh;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    padding-bottom: 0;
    padding-top: 30px;
}

.post-card {
  color: #ffffff;
  display: block;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 14px;
  /* background: hsla(248, 42%, 25%, 0.178);
  border: 1px solid rgba(164, 143, 255, 0.1215686275); */
  /* transition: transform 0.2s; */
}

/* .post-card:hover {
  transform: translateY(-5px);
} */

.post-header h3 {
  font-size: 20px;
  margin: 0 0 5px 0;
}

.post-header .post-meta {
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
}

.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: -moz-max-content;
    width: max-content;
    padding: 2px 8px;
    color: #F1F1F1;
    background-color: rgba(145, 129, 235, 0.288);
    transition: all 0.4s ease 0s;
    border-radius: 28px;
    border: 1px solid rgba(164, 143, 255, 0.1215686275);
    font-size: 12px;
    margin-right: 6px;
}

.post-content p {
  margin: 10px 0;
  color: #cccccc;
}

.post-title {
  border-bottom: none !important;
}

.post-title:hover {
  color: #1a6ed8;
}

.post-card:last-child {
  margin-bottom: 130px;
}

@media screen and (max-width: 1024px) {
  .post-container {
    width: 98%;
}
  h1 {
    font-size: 22px;
    margin-top: 170px;
  }

  .post-card:last-child {
  margin-bottom: 140px;
}

.global-image {
    opacity: 0.2;
    img {
      width: 200px;
      height: 280px;
    }
  }
}

/* book loader */
.loader {
  margin: 5% auto 30px;
}

.book {
  border: 2px solid #FFFFFF;
  width: 60px;
  height: 45px;
  position: relative;
  perspective: 150px;
  border-radius: 6px;
}

.page {
  display: block;
  width: 30px;
  height: 45px;
  border: 2px solid #FFFFFF;
  border-left: 1px solid #8455b2;
  margin: 0;
  position: absolute;
  right: -4px;
  top: -4px;
  overflow: hidden;
  background-color: rgba(145, 129, 235, 0.288);
  transform-style: preserve-3d;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  border-radius: 6px;
}

.book .page:nth-child(1) {
  -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
  animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
}

.book .page:nth-child(2) {
  -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
  animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
}

.book .page:nth-child(3) {
  -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
  animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
}


/* Page turn */

@-webkit-keyframes pageTurn {
  0% {
    -webkit-transform: rotateY( 0deg);
    transform: rotateY( 0deg);
  }
  20% {
    background: #4b1e77;
  }
  40% {
    background: rebeccapurple;
    -webkit-transform: rotateY( -180deg);
    transform: rotateY( -180deg);
  }
  100% {
    background: rebeccapurple;
    -webkit-transform: rotateY( -180deg);
    transform: rotateY( -180deg);
  }
}

@keyframes pageTurn {
  0% {
    transform: rotateY( 0deg);
  }
  20% {
    background: #4b1e77;
  }
  40% {
    background: rebeccapurple;
    transform: rotateY( -180deg);
  }
  100% {
    background: rebeccapurple;
    transform: rotateY( -180deg);
  }
}
</style>
